<template>
  <div class="registerContainer">
    <div class="main">
      <v-card width="50%" height="90%">
        <v-card-title class="primary--text">
          <v-avatar>
            <v-img
              :src="'/images/abonten-logo.png'"
              contain
              alt="logo"
              height="54"
            ></v-img>
          </v-avatar>
          Omnicom MediaGroup
        </v-card-title>
        <v-card-subtitle class="text-h6 mt-2"
          >Create an account</v-card-subtitle
        >

        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-progress-linear
              color="deep-purple accent-4"
              :active="loading"
              :indeterminate="loading"
            ></v-progress-linear>

            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-subheader>First Name </v-subheader>
                  <v-text-field
                    height="10"
                    width="200"
                    v-model.trim="first_name"
                    :rules="rules.fieldRules"
                    required
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-subheader>Last Name </v-subheader>
                  <v-text-field
                    v-model.trim="last_name"
                    :rules="rules.fieldRules"
                    required
                    filled
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-subheader>Job Title</v-subheader>
                  <v-text-field
                    v-model.trim="designation"
                    placeholder="e.g Senior Manager"
                    :rules="rules.fieldRules"
                    required
                    filled
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-subheader>Email Address </v-subheader>
                  <v-text-field
                    v-model.trim="email"
                    :rules="rules.emailRules"
                    required
                    filled
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-subheader>Organization </v-subheader>
                  <v-text-field
                    v-model.trim="organization"
                    :rules="rules.fieldRules"
                    required
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-subheader>Occupation </v-subheader>
                  <v-select
                    :rules="rules.fieldRules"
                    color="secondary"
                    :items="occupations"
                    v-model="occupation"
                    name="Occupation"
                    label="Occupation"
                    item-color="secondary"
                    :item-text="'title'"
                    :item-value="'item'"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    type="password"
                    color="secondary"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    v-model="password"
                    :rules="[...rules.passwordRules]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    color="secondary"
                    prepend-icon="mdi-lock"
                    name="confirmPassword"
                    label="Confirm Password"
                    v-model="confirmPassword"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    :type="show ? 'text' : 'password'"
                    :rules="[...rules.confirmPasswordRules, ...passwordsMatch]"
                    @blur="passwordsMatch"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="submitForm"> Register Client </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        <div class="suggestion">
          <p>Already a member? <a href="login">Sign in</a></p>
        </div>
      </v-card>
    </div>
    <alert></alert>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("auth");
export default {
  data() {
    return {
      show: false,
      loading: false,
      valid: false,
      rules: {
        fieldRules: [(v) => !!v || "Field is required"],
        passwordRules: [
          (v) => !!v || "Password cannot be Empty",
          (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
          (v) =>
            (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
        ],
        confirmPasswordRules: [
          (v) => !!v || "Password cannot be Empty",
          (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
          (v) =>
            (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
        ],

        emailRules: [
          (v) => !!v || "E-mail cannot be Empty",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },

      first_name: null,
      last_name: null,
      designation: null,
      email: null,
      organization: null,
      occupation: null,
      password: null,
      confirmPassword: null,

      occupations: [
        { item: "marketer", title: "Marketer" },
        { item: "media_owner", title: "Media Owner" },
        { item: "media_planner", title: "Media Planner" },
        { item: "other", title: "Other" },
      ],
    };
  },
  computed: {
    passwordsMatch() {
      return () =>
        this.password === this.confirmPassword || "Passwords do not Match";
    },
  },
  methods: {
    ...mapActions(["register"]),

    async submitForm() {
      this.$refs.form.validate();
      if (!this.valid) return;

      this.loading = true;

      // form is valid
      const newUser = await this.register({
        first_name: this.first_name,
        last_name: this.last_name,
        designation: this.designation,
        email: this.email,
        organization: this.organization,
        userType: this.occupation,
        password: this.password,
        subscription: this.subscription,
      });
      if (newUser) {
        this.loading = false;
        setTimeout(() => this.$router.push({ path: "/login" }), 3000);
      }
    },
  },
};
</script>

<style scoped>
.registerContainer {
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main {
  margin-right: 10px;
  background: url("/images/happy-client.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 23px 44px rgba(176, 183, 195, 0.14));
  border-radius: 8px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.container {
  padding: 0;
}
.v-card__title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 30px;
}
.v-card__subtitle {
  padding: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.v-radio .v-icon {
  color: var(--v-secondary-base);
}
.v-subheader {
  color: #000 !important;
}
#subscriptions {
  display: flex;
  align-items: center;
  justify-content: center;
}
#subscriptions p {
  padding-right: 10px;
  margin: 0;
}
.suggestion {
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #a3a3a3;
}
.suggestion a {
  color: rgb(63, 63, 207);
}
</style>
